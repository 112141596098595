.myStack {
    width: 100%;
    overflow-x: scroll;
}

img {
    height: 300px;
}

@media (max-width: 700px) {
    img {
        height: 250px;
    }
}