#icon-band{
    margin: 10px;
    color: white;
}

#banner_photo {
    padding: 2%;
    background-size: cover;
    background-position: center;
    height: 60vh;
}

#logo{
    width: 10%;
    height: auto;
}

iframe{
    width: 100%;
    height: 440px;
}


@media (max-width: 700px) {
    #logo{
        width: 40%;
        height: auto;
    }

    #banner_photo {
        padding: 2%;
        background-size: cover;
        background-position: center;
        height: 30vh;
    }

    iframe{
        height: 220px;
    }
    
}

.max-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Set the number of lines you want to display */
    -webkit-box-orient: vertical;
    cursor: pointer; /* Add cursor pointer to indicate the paragraph is clickable */
  }
  
  .full-text {
    overflow: visible; /* Show full text */
    -webkit-line-clamp: unset; /* Remove line clamp */
  }
  